var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.asset._id
  }, [_c('PageHeaderDisplay', {
    staticClass: "mb-3",
    attrs: {
      "title": _vm.asset.siteName,
      "sub-title": _vm._f("address")(_vm.asset.address),
      "company": _vm.asset.company,
      "entity": _vm.asset.entity
    }
  }), _vm.asset._id && _vm.asset.status !== 'active' ? _c('div', {
    staticClass: "content py-0"
  }, [_vm._m(0)]) : _vm._e(), _c('TabView', {
    attrs: {
      "route-name": "asset-view",
      "tabs": _vm.tabs,
      "loading": _vm.loadingAction.get,
      "exact": false
    }
  }, [_vm.asset._id ? _c('router-view') : _c('div', [_c('h2', [_vm._v("No asset has been selected, navigating back to list")])])], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-warning mb-0"
  }, [_c('strong', [_c('i', {
    staticClass: "fa fa-triangle-exclamation fa-fw"
  }), _vm._v(" INACTIVE")]), _vm._v(" This asset is currently inactive. ")]);
}]

export { render, staticRenderFns }