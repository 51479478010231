<template>
  <div>
    <div class="content pb-0 pt-2">
      <ul class="nav nav-tabs nav-tabs-alt justify-content-center justify-content-md-start">
        <li v-for="tab in visibleTabs" :key="tab.name" class="nav-item">
          <router-link
            :data-cy="tab.route"
            class="nav-link"
            :disabled="loading"
            :to="{ name: tab.route }"
            active-class="active"
            :exact="tab.exact !== undefined ? tab.exact : exact"
            :class="{ disabled: tab.isComingSoon, active: tab.altRoutes?.includes($route.name) }"
          >
            <i v-if="typeof tab.icon === 'string' || tab.icon instanceof String" class="fa-duotone fa-fw text-muted mr-1" :class="tab.icon"></i>
            <component :is="tab.icon" v-else class="mr-1"></component>
            <span class="d-none d-md-inline text-capitalize ml-1">{{ tab.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="content">
      <Spinner v-if="loading" class="py-5" />
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/SpinnerLogo';

export default {
  name: 'TabView',
  components: {
    Spinner
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    exact: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    currentTab() {
      return this.$route.name || this.tabs[0].name;
    },
    visibleTabs() {
      return this.tabs.filter(tab => this.$permissions.routeName(tab.route));
    }
  }
};
</script>
